<template>
<div class="OrderManagement">
  <a-spin :spinning="spinning">
  <div style="border-bottom: 1px solid #edeff2;">
    <div class="moTitle">订单及开票记录</div>
  </div>
  <div class="btn">
    <a-button type="primary" @click="paidOrderBtn">合并开票</a-button>
  </div>
    <div v-if="total === 0"><NullDataPrompt message="您还没有订单呢 ~"/></div>
  <div v-else class="content-card">
    <div class="card" v-for="(item,index) in orderList" :key="index">
      <div class="cardTop">
        <span><span class="topTitle">订单号：</span>{{item.orderNumber}}</span>
        <span class="cardTop-font"><span class="topTitle">下单时间：</span>{{item.orderTime}}</span>
        <span><span class="topTitle">支付时间：</span>{{item.paymentTime?item.paymentTime:'-'}}</span>
      </div>
      <a-table :columns="columns" :data-source="item.orderTable" :pagination="false">
      <span slot="orderMoneyYj" slot-scope="text,record,index">
        ￥{{text}}
      </span>
        <span slot="payType" slot-scope="text, record, index">
<!--          对公支付Corporate微信支付WeChat-->
          <span v-if="text === 'Corporate'">对公支付</span>
          <span v-if="text === 'WeChat'">微信支付</span>
        </span>
      <span slot="orderMoney" slot-scope="text,record,index">
        <span v-if="text">￥{{text}}</span>
        <span v-else>-</span>
      </span>
      <span slot="orderStatus" slot-scope="text,record,index">
<!--         0待付款 1已取消 2未付款 3待确认 4已支付  -->
         <a-tag v-if="text === 0" color="red">待付款</a-tag>
         <a-tag v-if="text === 1">已取消</a-tag>
         <a-tag v-if="text === 2">未付款</a-tag>
         <a-tag v-if="text === 3" color="blue">待确认</a-tag>
         <a-tag v-if="text === 4" color="green">已支付</a-tag>
      </span>
        <span slot="orderFavorablePrice" slot-scope="text,record">
<!--          付款后才显示优惠金额-->
          <span v-if="record.orderStatus === 4">￥{{record.orderFavorablePrice}}</span>
          <span v-else>-</span>
        </span>
        <span slot="action" slot-scope="text,record">
          <!--         0待付款 1已取消 2未付款 3待确认 4已支付  -->
        <a class="action-font" v-if="record.orderStatus === 0 && (record.orderMoneyYj || record.orderMoney)" @click="continueBtn(record)">继续</a>
          <a-popconfirm
              class="action-font"
              v-if="record.orderStatus === 0"
              placement="top"
              @confirm="cancelOrder(record)">
            <template slot="title">
              是否确认取消订单？
            </template>
            <a>取消</a>
          </a-popconfirm>
<!--        <a class="action-font" v-if="record.orderStatus === 3 || record.orderStatus === 4">-->
<!--          查看明细-->
<!--        </a>-->
        <!--  isInvoicing: 开票状态 0未开票 1已申请 2 已开票  -->
          <span v-if="record.orderStatus === 4 && record.isInvoicing === 0">
            <span v-if="record.orderMoneyYj || record.orderMoney"><a @click="ApplyBilling(record)" class="action-font">申请开票</a></span>
            <span v-else>-</span>
          </span>
<!--        <a @click="ApplyBilling(record)" class="action-font" v-if="record.orderStatus === 4 && record.isInvoicing === 0">-->
<!--          申请开票-->
<!--        </a>-->
        <span class="action-font" style="color: #b3b1b1" v-if="record.orderStatus === 4 && record.isInvoicing === 1">已申请开票</span>
        <span class="action-font" style="color: #b3b1b1" v-if="record.orderStatus === 4 && record.isInvoicing === 2">已开票</span>
        <a-popconfirm
            v-if="record.orderStatus === 1 || record.orderStatus === 2"
            title="是否确认删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirmOrder(record.id)"
         ><a class="action-font">删除</a>
        </a-popconfirm>
        <a v-if="record.payType === 'Corporate' && record.orderStatus === 4" @click="checkOrder(record)">支付凭证</a>
      </span>
      </a-table>
    </div>
  </div>
  <div style="width: 100%;margin-top:10px;margin-bottom: 20px" v-show="total > 10">
    <div style="display: flex;justify-content: center">
      <a-pagination
          show-quick-jumper
          :current="page_no"
          :pageSize="5"
          :total="total"
          @change="paginationChange" />
    </div>
  </div>
    <!-- 查看照片 -->
    <a-modal
        :footer="null"
        title="付款凭证"
        :visible="imgObj.imgShow"
        @cancel="()=>{this.imgObj.imgShow = false;}">
      <div style="width: 100%;display: flex;justify-content: center">
        <img :src="imgObj.url" style="width: 400px;" />
      </div>
      <div>支付时间：{{imgObj.time}}</div>
    </a-modal>
<!--  申请开票-->
  <a-drawer
      title="申请开票"
      placement="right"
      @close="close"
      :visible="applyBillingVisible"
      :get-container="false"
      width="1200"
  >
    <div class="applyBilling">
      <div class="title">01 确认发票抬头</div>
      <div class="table">
        <a-table :columns="invoice" :data-source="invoiceData" :pagination="false" :scroll="{ x: 2000 }">
          <a slot="action" slot-scope="text,record" @click="invoiceEdit(record)">修改</a>
          <span slot="name" slot-scope="text,record">
      <span v-if="memberStatus === 2">
        <!--      企业-->
        {{text}}
<!--        <a-tag color="red">企业</a-tag>-->
      </span>
            <!--      个人-->
      <span v-if="memberStatus === 1">
        {{text}}
<!--        <a-tag color="purple">个人</a-tag>-->
      </span>
    </span>
          <span slot="InvoiceType" slot-scope="text,record">{{record.invoiceType ? record.invoiceType :'无'}}</span>
          <span slot="taxNumber" slot-scope="text,record">{{text?text:'-'}}</span>
          <span slot="bank" slot-scope="text,record">{{text?text:'-'}}</span>
          <span slot="bankAccount" slot-scope="text,record">{{text?text:'-'}}</span>
          <span slot="address" slot-scope="text,record">{{text?text:'-'}}</span>
          <span slot="phone" slot-scope="text,record">{{text?text:'-'}}</span>
        </a-table>
      </div>
      <div class="title">02 选择发票介质</div>
      <a-radio-group class="content" v-model="InvoiceMedium" @change="onChange_InvoiceMedium">
        <a-radio value="电子发票" :disabled="invoiceList.invoiceType === '增值税专用发票'">
          电子发票
        </a-radio>
<!--        <a-radio value="纸质发票">-->
<!--          纸质发票（提交申请成功后，将于3个工作日内为您开具邮票）-->
<!--        </a-radio>-->
      </a-radio-group>
      <div class="title">03 选择发票内容</div>
      <a-radio-group class="content" v-model="invoiceContent" @change="onChange_invoiceContent">
        <a-radio value="技术服务费">
          技术服务费
        </a-radio>
      </a-radio-group>
      <div>
        <!-- 邮寄地址 -->
        <div v-if="InvoiceMedium === '纸质发票'">
          <div class="title">04 邮寄地址</div>
          <a-table
              :columns="mailingAddressTable"
              :data-source="mailingAddressData"
              :rowKey="(record, index) => {return record.id;}"
              :row-selection="rowSelection"
              :pagination="false">
            <span slot="address" slot-scope="text, record, index">
              <span>{{ record.addressProvince }}{{ record.addressCity }}{{ record.addressDetail }}</span>
            </span>
            <span slot="operation" slot-scope="text, record, index">
              <a-tag v-if="record.isDefault == 1" color="#108ee9">默认地址</a-tag>

              <a v-if="record.isDefault == 0"
                  @click="setDefault(record.id)"
                  style="margin-right: 15px">设置默认</a>

              <a @click="edit(record)">修改</a>
             <a-popconfirm
                 style="margin-left: 8px"
                 title="确认删除?"
                 ok-text="确认"
                 cancel-text="取消"
                 @confirm="confirmDelAddress(record.id)">
               <a>删除</a>
             </a-popconfirm>
            </span>
          </a-table>
          <div style="margin-top: 10px"><a @click="addMailing">+  添加邮寄地址</a>  新增修改邮寄地址，将同步更新到 账号管理-邮寄地址管理</div>
        </div>
        <!-- 邮箱地址 -->
        <div v-if="InvoiceMedium === '电子发票'">
          <div class="title">04 邮箱地址</div>
          <div class="content"><span style="color: red">* </span>电子邮件：<a-input style="width: 300px" v-model="emileAddress" placeholder="请输入邮箱"/></div>
        </div>
      </div>
      <div class="title">05 填写发票备注（选填）</div>
      <div style="height: 300px">
        <a-textarea
            @change="textareaChange"
            v-model="InvoiceRemarks"
            placeholder="填写后打印在发票的备注内容区域"
            :auto-size="{ minRows: 10, maxRows: 10 }"
        />
        <div style="color: #c1bfbf;font-size: 12px;margin-top: 10px">发票备注不可超过230个字节，一个中文字符为2个字节</div>
      </div>
    </div>
    <div
        :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
    >
      <div style="font-size: 18px;color: #333333;display: flex;justify-content: space-between">
        <div> 待开票金额 <span style="font-size: 25px;color: red">{{ invoiceValue }}元</span>
<!--          此纸质版发票预计<span style="color: red">3个工作日</span>内寄出-->
        </div>
        <div>
          <a-button @click="close"> 取消 </a-button> &ensp;
          <a-button type="primary" @click="submit"> 提交 </a-button>
        </div>
      </div>
    </div>
  </a-drawer>
<!--  服务明细-->
  <a-modal
      width="600px"
      title="服务明细"
      :visible="ServicesDetails"
      :footer="null">
    <a-alert message="如果您在使用过程中遇到任何问题，请联系客服或致电 400-606-0201" type="info" show-icon />
    <a-table :columns="ServicesColumns" :data-source="ServicesData" :pagination="false" bordered style="margin-top: 20px">
      <span slot="match" slot-scope="text">{{text?text:'--'}}</span>
    </a-table>
    <div style="display: flex;justify-content: right;margin-top: 10px">
      实际支付： <span style="font-size: 18px;color: red">¥8,999</span>
    </div>
  </a-modal>
<!--  合并开票-->
  <a-modal
      width="700px"
      @cancel="handleCancel"
      :footer="null"
      title="合并开票"
      :visible="billingConsolidator">
    <a-table :row-selection="{ selectedRowKeys: billingConsolidatorKeys, onChange: onSelectChange }"
             :columns="consolidatorColumns"
             :data-source="consolidatorData"
             style="margin-bottom: 50px"
             :pagination="false">
      <span slot="orderMoney" slot-scope="text">
        ￥{{text?text:0}}
      </span>
      <span slot="orderStatus" slot-scope="text">
         <a-tag v-if="text === 0" color="red">待付款</a-tag>
         <a-tag v-if="text === 1">已取消</a-tag>
         <a-tag v-if="text === 2">未付款</a-tag>
         <a-tag v-if="text === 3" color="blue">待确认</a-tag>
         <a-tag v-if="text === 4" color="green">已支付</a-tag>
      </span>
    </a-table>
    <div
        :style="{
            position: 'absolute',
            textAlign:'right',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
    >
      <a-button @click="handleCancel"> 取消 </a-button> &ensp;
      <a-button type="primary" @click="handleOk" :disabled="selectHBList.length < 2"> 申请开票 </a-button>
    </div>
  </a-modal>
<!-- 新增邮寄地址 -->
  <a-modal
      :title="mailingTitle"
      :visible="mailingVisible"
      @ok="mailingOk"
      @cancel="mailingCancel"
  >
    <a-form-model
        :model="form"
        :rules="ruleform"
        ref="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
    >
      <a-form-model-item label="收件人姓名" prop="name">
        <a-input v-model="form.name" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item
          label="邮寄地址"
          prop="address"
          placeholder="请选择省市"
      >
        <a-cascader
            allowClear
            v-model="form.address"
            :options="options"
            placeholder="请选择省市"
            @change="provinceChange"
        />
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="addressDetail">
        <a-input
            v-model="form.addressDetail"
            placeholder="填写街道地址，不必重复省市信息"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="联系方式" prop="phone">
        <a-input v-model="form.phone" placeholder="请输入"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
<!-- 修改发票信息 -->
  <a-modal
      width="700px"
      title="修改发票信息"
      :visible="invoiceInfoVisible"
      @ok="invoiceInfoOk"
      @cancel="invoiceInfoCancel"
  >
<!--    memberStatus  0未认证 1个人认证 2企业认证-->
<!--    <div v-if="memberStatus === 2">-->
      <a-form-model
          :model="invoiceInfoForm"
          :rules="invoiceInfoRuleform"
          ref="invoiceInfoFormRef"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item label="发票类型" prop="invoiceType">
          <a-radio-group v-model="invoiceInfoForm.invoiceType" @change="selectInvoice">
            <a-radio value="增值税普通发票">
              增值税普通发票
            </a-radio>
            <a-radio value="增值税专用发票">
              增值税专用发票
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="发票抬头" prop="name">
<!--          企业的 -- 发票抬头 -- 不能修改-->
          <a-input
              :disabled="this.memberStatus === 2"
              v-model="invoiceInfoForm.name"
              placeholder="请输入"
          ></a-input>
          <a-popover placement="top" v-if="memberStatus === 2">
            <template slot="content">
              <div style="width: 300px">发票抬头须与账号实名认证主体一致，若您要修改发票抬头需先<a @click="openRealNameAuthentication(infoList,memberStatus === 2 ?'DW':'')">修改实名认证信息</a>。</div>
            </template>
            <span style="color: #afafaf">如何修改专票抬头</span>
          </a-popover>
        </a-form-model-item>
        <a-form-model-item label="纳税人识别号" prop="taxNumber">
          <a-input
              v-model="invoiceInfoForm.taxNumber"
              placeholder="开企业抬头发票必填"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="基本开户银行" prop="bank">
          <a-input
              v-model="invoiceInfoForm.bank"
              placeholder="请输入"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="基本银行账号" prop="bankAccount">
          <a-input
              v-model="invoiceInfoForm.bankAccount"
              placeholder="请输入"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label=" 公司注册地址" prop="address">
          <a-input
              v-model="invoiceInfoForm.address"
              placeholder="填写街道地址，不必重复省市信息"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label=" 公司注册电话" prop="phone">
          <a-input v-model="invoiceInfoForm.phone" placeholder="请输入"></a-input>
        </a-form-model-item>
      </a-form-model>
<!--    </div>-->
<!--    <div v-if="memberStatus === 1">-->
<!--      <a-alert style="margin-bottom: 20px" message="请您与贵司财务人员核实发票信息，确保信息填写正确，以免影响发票的后续使用。" type="info" show-icon />-->
<!--      <a-form-model-->
<!--          :model="invoiceInfoFormG"-->
<!--          :rules="invoiceInfoRuleformG"-->
<!--          ref="invoiceInfoFormRefG"-->
<!--          :label-col="{ span: 5 }"-->
<!--          :wrapper-col="{ span: 17 }"-->
<!--      >-->
<!--        <a-form-model-item label="发票类型" prop="invoiceType">-->
<!--          <a-radio-group v-model="invoiceInfoFormG.invoiceType">-->
<!--            <a-radio value="增值税普通发票">-->
<!--              增值税普通发票-->
<!--            </a-radio>-->
<!--            <a-radio value="增值税专用发票">-->
<!--              增值税专用发票-->
<!--            </a-radio>-->
<!--          </a-radio-group>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="发票抬头" prop="name">-->
<!--          <a-input-->
<!--              :disabled="true"-->
<!--              v-model="invoiceInfoFormG.name"-->
<!--              placeholder="填写街道地址，不必重复省市信息"-->
<!--          ></a-input>-->
<!--          <a-popover placement="top">-->
<!--            <template slot="content">-->
<!--              <div style="width: 300px">发票抬头须与账号实名认证主体一致，若您要修改发票抬头需先<a @click="openRealNameAuthentication(infoList)">修改实名认证信息</a>。</div>-->
<!--            </template>-->
<!--            <span style="color: #afafaf">如何修改专票抬头</span>-->
<!--          </a-popover>-->
<!--        </a-form-model-item>-->
<!--      </a-form-model>-->
<!--    </div>-->
  </a-modal>
  <!--认证-->
  <RealNameAuthentication
      :visible="visible"
      :jurisdiction="false"
      :isShow="isShow"
      v-on:closeMainRealNameAuth="closeMain"
      v-on:checkList="checkList"
  ></RealNameAuthentication>
    <!--    确认产品信息 v-if="ProductVisible" -->
    <ProductInfor
        v-if="ProductVisible"
        :visible="ProductVisible"
        :orderNumber="orderNumber"
        :isNextGetOrderList="true"
        v-on:closeMainProduct="closeMainProduct"
        v-on:OpenPayModel="OpenPayModel"
    />
    <!--    订单支付  -->
    <pay-indent
        v-if="payVisible"
        :orderNumber="orderNumber"
        :visible="payVisible"
        v-on:closeMain="closeMainPay"
    />
  </a-spin>
</div>
</template>

<script>
import RealNameAuthentication from "@/components/Authentication/RealNameAuthentication";
import NullDataPrompt from "@/components/NullDataPrompt";
import variables from "@/assets/base.scss";
import City from "@/assets/city.json";
import {
  cancellationByOrderNumber,
  delOrder, dutyParagraphIsRepeat, getAuthenticationInformation,
  getOrder,
  invoiceHeader,
  paidOrder,
  postInvoice,
  updatebyid
} from "@/service/authentication";
import {delAddress, editAddress, getAddressList, mailingAddress, newAddress} from "@/service/columnmanageapi";
import {conventionNoPayOrder} from "@/service/MedicalConference";
import ProductInfor from "@/components/PaidView/ProductInfor";
import payIndent from "@/components/PaidView/payIndent";
const columns = [
  {
    title: '订单类型',
    dataIndex: 'orderType',
    key: 'orderType',
    width:'12%',
    scopedSlots: { customRender: 'orderType' },
  },
  {
    title: '订购服务',
    dataIndex: 'serviceName',
    key: 'serviceName',
    width:'12%',
    scopedSlots: { customRender: 'serviceName' },
  },
  {
    title: '订单金额',
    dataIndex: 'orderMoneyYj',
    key: 'orderMoneyYj',
    width:'12%',
    scopedSlots: { customRender: 'orderMoneyYj' },
  },
  {
    title: '优惠金额',
    dataIndex: 'orderFavorablePrice',
    key: 'orderFavorablePrice',
    width:'12%',
    scopedSlots: { customRender: 'orderFavorablePrice' },
  },
  {
    title: '实付金额',
    dataIndex: 'orderMoney',
    key: 'orderMoney',
    width:'12%',
    scopedSlots: { customRender: 'orderMoney' },
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    width:'12%',
    scopedSlots: { customRender: 'orderStatus' },
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    key: 'payType',
    width:'12%',
    scopedSlots: { customRender: 'payType' },
  },
  // {
  //   title: '生效时间',
  //   dataIndex: 'effectiveTime',
  //   width:'12%',
  //   key: 'effectiveTime',
  // },
  // {
  //   title: '到期时间',
  //   dataIndex: 'expirationTime',
  //   key: 'expirationTime',
  //   width:'12%',
  // },
  {
    title: '操作',
    width:'14%',
    scopedSlots: { customRender: 'action' },
  },
];
const invoice = [
  {
    title: '发票抬头',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },{
    title: '发票类型',
    dataIndex: 'invoiceType',
    key: 'invoiceType',
    scopedSlots: { customRender: 'InvoiceType' },
  },{
    title: '纳税人识别号',
    dataIndex: 'taxNumber',
    key: 'taxNumber',
    scopedSlots: { customRender: 'taxNumber' },
  },{
    title: '基本开户银行',
    dataIndex: 'bank',
    key: 'bank',
    scopedSlots: { customRender: 'bank' },
  },{
    title: '基本开户账号',
    dataIndex: 'bankAccount',
    key: 'bankAccount',
    scopedSlots: { customRender: 'bankAccount' },
  },{
    title: '公司注册地址',
    dataIndex: 'address',
    key: 'address',
    width:'300px',
    scopedSlots: { customRender: 'address' },
  },{
    title: '公司注册电话',
    dataIndex: 'phone',
    key: 'phone',
    scopedSlots: { customRender: 'phone' },
  },{
    title: '操作',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];
const invoiceData = [];

const mailingAddressTable = [
    {
      title: "收件人",
      dataIndex: "name",
      width: "13%",
    },
    {
      title: "联系方式",
      dataIndex: "phone",
      width: "17%",
    },
    {
      title: "收件地址",
      dataIndex: "address",
      width: "40%",
      scopedSlots: { customRender: "address" },
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "30%",
      scopedSlots: { customRender: "operation" },
    },
];
const mailingAddressData = [];

var moneyArr = [];
var positionage = 0;
const renderContentMoney = (value, row, index) => {
  const obj = {
    children: value,
    attrs: {},
  };
  const _row = moneyArr[index];
  obj.attrs = {
    rowSpan: _row,
  };

  return obj;
};
const rowspan = (userData) => {
  moneyArr = [];
  positionage = 0;
  userData.forEach((item, index) => {
    if (index === 0) {
      moneyArr.push(1);
      positionage = 0;
    } else {
      //需要合并的地方判断
      if (userData[index].money === userData[index - 1].money) {
        moneyArr[positionage] += 1;
        moneyArr.push(0);
      } else {
        moneyArr.push(1);
        positionage = index;
      }
    }
  });
  // console.log(moneyArr);
};
const ServicesColumns = [
  {
    title: '服务',
    dataIndex: 'serve',
    key: 'serve',
    scopedSlots: { customRender: 'serve' },
  },{
    title: '选配',
    dataIndex: 'match',
    key: 'match',
    scopedSlots: { customRender: 'match' },
  },{
    title: '金额',
    dataIndex: 'money',
    customRender: renderContentMoney,
  },
];
const ServicesData = [
  {
    serve:'购买版本',
    match:'旗舰版',
    money:'8999',
  },{
    serve:'人数',
    match:'15',
    money:'8999',
  },{
    serve:'版本功能',
    match:'',
    money:'8999',
  },{
    serve:'容量',
    match:'',
    money:'8999',
  },
];
const consolidatorColumns = [
  {
    title: '订单号',
    dataIndex: 'orderNumber',
    key: 'orderNumber',
    scopedSlots: { customRender: 'orderNumber' },
  },{
    title: '订单类型',
    dataIndex: 'orderType',
    key: 'orderType',
    scopedSlots: { customRender: 'orderType' },
  },{
    title: '下单金额',
    dataIndex: 'orderMoney',
    key: 'orderMoney',
    scopedSlots: { customRender: 'orderMoney' },
  },{
    title: '订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' },
  },
];
const consolidatorData = [];
export default {
name: "OrderManagement",
  components:{RealNameAuthentication,NullDataPrompt,ProductInfor,payIndent},
  data(){
    let validatePass = async (rule, value, callback) => {
      if(value){
        const reg = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/;
        if (!value) {
          return callback(); //取消报错
        } else {
          if (!reg.test(value)) { //格式错误
            return callback(new Error('请输入正确的统一社会信用代码'));
          }
          else { //格式正确，判断是否重复
            let data = {
              type:'taxNumber', // 类型 isCard:身份证号码  taxNumber:信用码
              taxNumber:value
            }
            const response = await dutyParagraphIsRepeat(data)
            if(response.code === 0) {
              if (response.data) { //没重复
                return callback(); //取消报错
              } else { //重复
                return callback(new Error('统一社会信用代码已存在，请重新输入'));
              }
            }
          }
        }
      }else {
        return callback(new Error('请输入统一社会信用代码'));
      }
    };
  return{
    payVisible:false,
    spinning:false,
    form: {},
    ruleform: {
      name: [
        { required: true, message: "请输入", trigger: "blur" },
        {
          validator: function (rule, value, callback) {
            if (value.trim().length === 0) {
              callback(new Error("请输入正确的内容"));
            } else {
              //校验通过
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      address: [{ required: true, message: "请选择", trigger: "change" }],
      addressDetail: [
        { required: true, message: "请输入", trigger: "blur" },
        {
          validator: function (rule, value, callback) {
            if (value.trim().length === 0) {
              callback(new Error("请输入正确的地址"));
            } else {
              //校验通过
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      phone: [
        { required: true, message: "请输入", trigger: "blur" },
        {
          pattern: /^1[3456789]\d{9}$/,
          message: "请输入正确的手机号码",
          trigger: "blur",
        },
      ],
    },
    invoiceInfoForm:{},
    invoiceInfoRuleform:{},
    invoiceInfoRuleformZ:{ //专业发票
      invoiceType: [{ required: true, message: "请输入", trigger: "change" }],
      name: [{ required: true, message: "请输入", trigger: "blur" }],
      taxNumber: [
          { required: true, validator: validatePass, trigger: "blur" },

      ],
      address: [{ required: true, message: "请输入", trigger: "blur" }],
      bank: [{ required: true, message: "请输入", trigger: "blur" }],
      bankAccount: [{ required: true, message: "请输入", trigger: "blur" }],
      phone: [
        { required: true, message: "请输入", trigger: "blur" },
        // {
        //   pattern: /^1[3456789]\d{9}$/,
        //   message: "请输入正确的手机号码",
        //   trigger: "blur",
        // },
      ],
    },
    invoiceInfoRuleformP:{ //普通发票 -- 企业
      invoiceType: [{ required: true, message: "请输入", trigger: "change" }],
      name: [{ required: true, message: "请输入", trigger: "blur" }],
      taxNumber: [{ required: true, validator: validatePass, trigger: "blur" }],
    },
    invoiceInfoFormG:{},
    invoiceInfoRuleformG:{ // 普通发票 -- 个人
      invoiceType: [{ required: true, message: "请输入", trigger: "change" }],
      name: [{ required: true, message: "请输入", trigger: "blur" }],
    },
    ServicesColumns,
    ServicesData,
    consolidatorColumns,
    consolidatorData,
    selectedRowKeys: [],
    // selectedRowKeysH: [], //合并开票
    variables,
    columns,
    mailingAddressTable,
    mailingAddressData,
    invoiceValue:null,
    invoice,
    invoiceData,
    invoiceList:{},
    InvoiceMedium:null,
    emileAddress:null,
    servicesDetailsVisible:false,
    applyBillingVisible:false,
    mailingVisible: false,
    mailingTitle:'',
    invoiceContent:null,
    page_no:1,
    total:10,
    InvoiceRemarks:'',
    ServicesDetails:false,
    billingConsolidator:false,
    billingConsolidatorKeys:[],
    selectHBList:[],
    options: [],
    City: City,
    invoiceInfoVisible:false,
    orderList:[],
    userId:null,
    memberStatus:null,

    infoList: {},
    isShow: {},
    visible:false,
    userInfoList:{},

    ProductVisible:false,
    ProductInforList:{},

    orderNumber:'',

    orderId:"",


    imgObj:{
      imgShow:false,
      time:'',
      url:'',
    },
  }
  },
  created() {
    this.getInformation();
    const user = JSON.parse(localStorage.getItem("yichi_userInfo"));
    this.userId = user.id;
    // this.memberStatus = user.memberStatus; //身份 个人/企业
    this.getPCH();
    rowspan(ServicesData);
    this.getOrderList();
  },
  computed:{
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
        },
        type: "radio",
      };
    },
  },

  methods:{
    async getIsShowIssueBtn() {
      const response = await conventionNoPayOrder(this.orderNumber)
      if(response.code === 200){
        this.ProductInforList = response.data;
      }else {
        this.$message.warning(response.message);
      }
    },
    closeMainProduct(val){
      this.ProductVisible = val;
      //刷新列表
      // this.orderList = [];
      // this.getOrderList();
    },
    OpenPayModel(){
      this.payVisible = true;
    },
    closeMainPay(val){
      this.payVisible = val;

      //过几秒再掉
      setTimeout(()=>{
        //刷新列表
        this.orderList = [];
        this.getOrderList();
      },1500)

    },
    // getInformation() {},
    closeMain(val) {
      this.visible = val;
    },
    checkList(val) {
      this.getInvoiceHeader();
    },

    async openRealNameAuthentication(row, type) {
      //获取基本信息
      const response = await getAuthenticationInformation();
      if (response.code === 200) {

        // this.infoList = response.data;
        let row = response.data;
        if (type === "DW") {
          //判断当type为DW时，为企业，自动切换到企业认证界面
          this.$set(row, "companyStatusDW", 2);
        } else {
          this.$set(row, "companyStatusDW", row.memberStatus);
        }
        this.isShow = row;
        this.visible = true;
        this.invoiceInfoVisible = false;

      } else {
        this.$message.warning(response.message);
      }
    },
    continueBtn(row){
      // this.$router.push({path:'/orderServices'})
      this.orderId = row.id;
      this.orderNumber = row.orderNumber;
      // this.getIsShowIssueBtn();
      this.ProductVisible = true;
    },
    selectInvoice(){ //选择发票类型;
      if(this.memberStatus === 2){ //企业
        if(this.invoiceInfoForm.invoiceType === '增值税普通发票'){
          this.invoiceInfoRuleform = this.invoiceInfoRuleformP;
        }else if(this.invoiceInfoForm.invoiceType === '增值税专用发票'){
          this.invoiceInfoRuleform = this.invoiceInfoRuleformZ;
        }
      }else if(this.memberStatus === 1){//个人
        if(this.invoiceInfoForm.invoiceType === '增值税普通发票'){
          this.invoiceInfoRuleform = this.invoiceInfoRuleformG;
        }else if(this.invoiceInfoForm.invoiceType === '增值税专用发票'){
          this.invoiceInfoRuleform = this.invoiceInfoRuleformZ;
        }
      }
    },
    //修改发票抬头
    invoiceEdit(record){
      //判断是企业还是个人
      if(this.memberStatus === 2){ //企业
        this.invoiceInfoForm = {
          id:record.id,
          invoiceType:record.invoiceType,
          name:record.name,
          taxNumber:record.taxNumber,
          address:record.address,
          bank:record.bank,
          bankAccount:record.bankAccount,
          phone:record.phone,
        }
        if(!record.invoiceType){  //发票类型为空时，默认普票
          this.invoiceInfoForm.invoiceType = '增值税普通发票';
          this.invoiceInfoRuleform = this.invoiceInfoRuleformP;
        }else {
          if(record.invoiceType === '增值税专用发票'){
            this.invoiceInfoRuleform = this.invoiceInfoRuleformZ;
          }else if(record.invoiceType === '增值税普通发票'){
            this.invoiceInfoRuleform = this.invoiceInfoRuleformP;
          }
        }
        this.invoiceInfoVisible = true;
      }else if(this.memberStatus === 1){ //个人
        // this.invoiceInfoFormG = {
        //   invoiceType:record.invoiceType,
        //   name:record.name,
        // }
        // this.invoiceInfoFormG.invoiceType = '增值税普通发票';
        // this.invoiceInfoRuleform = this.invoiceInfoRuleformP;
        //
        // this.invoiceInfoVisible = true;

        this.invoiceInfoForm = {
          id:record.id,
          invoiceType:record.invoiceType,
          name:record.name ? record.name : '个人',

          taxNumber:record.taxNumber,
          address:record.address,
          bank:record.bank,
          bankAccount:record.bankAccount,
          phone:record.phone,
        }

        if(!record.invoiceType){  //发票类型为空时，默认普票
          this.invoiceInfoForm.invoiceType = '增值税普通发票';
          this.invoiceInfoRuleform = this.invoiceInfoRuleformG;
        }else {
          //个人 -- 专票
          if (record.invoiceType === '增值税专用发票') {
            this.invoiceInfoRuleform = this.invoiceInfoRuleformZ;
          } else if (record.invoiceType === '增值税普通发票') {
            //个人 -- 普票
            this.invoiceInfoRuleform = this.invoiceInfoRuleformG;
          }
        }
        // this.invoiceInfoForm.invoiceType = '增值税普通发票';
        // this.invoiceInfoRuleform = this.invoiceInfoRuleformP;

        this.invoiceInfoVisible = true;
      }else if(this.memberStatus === 0){
        this.$message.warning("未认证或者认证未通过！")
      }
    },
    // 获取地址
    async addressList() {
      this.selectedRowKeys = []; //清空选择
      const res = await getAddressList(this.userId);
      if (res.code === 0) {
        this.mailingAddressData = res.data;
        //设置自动选中默认地址
        for (let i=0;i<res.data.length;i++){
          if(res.data[i].isDefault){
            //默认地址
            let obj = res.data[i].id;
            let arr = [];
            arr.push(obj);
            this.selectedRowKeys = arr;
          }
        }
      }
    },
    // 设置为默认
    async setDefault(id) {
      const res = await mailingAddress(id);
      if (res.code == 0) {
        this.$message.success("设置默认邮寄地址成功");
        await this.addressList();
      } else {
        this.$message.warning(res.message);
      }
    },
    // 修改地址
    edit(record) {
      this.mailingTitle = '修改邮寄地址'
      this.form = record;
      this.form.address = [record.addressProvince, record.addressCity];
      this.mailingVisible = true;
    },
    //  删除邮寄地址
    async confirmDelAddress(id) {
      const res = await delAddress(id);
      if (res.code == 0) {
        this.$message.success("删除成功");
        await this.addressList();
      } else {
        this.$message.warning(res.message);
      }
    },
    //获取个人信息
    async getInformation() {
      const response = await getAuthenticationInformation();
      if (response.code === 200) {
        this.userInfoList = response.data;
        this.memberStatus = response.data.memberStatus; //身份 个人/企业
      } else {
        this.$message.warning("name", response.message);
      }
    },
    ApplyBilling(record){
      //判断用户认证过程中不能开发票
      if(this.userInfoList.companyStatus !== 3){ // 0-未提交认证 1已提交待审核 2已审核未通过 3已通过审核
        this.$message.info("请认证审核通过后再开发票！");
      }else {
        this.selectHBList = record.orderTable;
        this.getInvoiceHeader();
        this.addressList();
        this.invoiceValue = record.orderMoney;
        this.applyBillingVisible = true;
        // //默认选择电子发票
        // this.InvoiceMedium = '电子发票';
        //选择发票内容
        this.invoiceContent = '技术服务费';
      }
    },
    //获取发票抬头信息
    async getInvoiceHeader() {
      const response = await invoiceHeader();
      if (response.code === 0) {
        this.invoiceData = [response.data];
        this.invoiceList = response.data;
        //电子发票只允许开增值税普通发票，纸质版发票可开增值税专用发票/增值税普通发票
        if(response.data.invoiceType === '增值税专用发票'){
          this.InvoiceMedium = '纸质发票';
        }else {
          this.InvoiceMedium = '电子发票';
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    paidOrderBtn(){
        this.getPaidOrderList();
    },
    handleCancel(){
      this.billingConsolidator = false;
      this.billingConsolidatorKeys = [];
    },
    //获取可合并开票订单列表
    async getPaidOrderList() {
      const response = await paidOrder();
      if (response.code === 0) {
        if(response.data.length >=2){
          this.consolidatorData = response.data;
          this.billingConsolidator = true;
        }else {
         this.$message.info("两项开票以上才可以合并开票~")
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    async confirmOrder(id) {
      const response = await delOrder(id);
      if (response.code === 200) {
        //刷新列表
        this.orderList = [];
        await this.getOrderList();
      } else {
        this.$message.warning(response.message);
      }
    },
    checkOrder(record) {
      this.imgObj.time = record.paymentTime;
      this.imgObj.url = record.bankReceipt;
      this.imgObj.imgShow = true;
    },
    cancelOrder(record){ //取消订单
      let data = {
        "orderNumber":record.orderNumber,
        "cancelType":"商户取消"
      }
      this.putAncellationOrder(data);
    },
    async putAncellationOrder(data) {
      const response = await cancellationByOrderNumber(data);
      if (response.code === 200) {
        this.$message.success("订单已取消！")
        //刷新列表
        this.orderList = [];
        await this.getOrderList();
      }else {
        this.$message.warning(response.message);
      }
    },
    //获取订单列表
    async getOrderList() {
      this.spinning = true;
      let data = {
        page_no:this.page_no,
        page_size:5
      }
      const response = await getOrder(data);
      if (response.code === 0) {
        for (let i=0;i<response.data.length;i++){
          let arr = [];
          arr.push(response.data[i]);
          this.$set(response.data[i],'orderTable',arr);
        }
        this.orderList = response.data;
        this.total = response.count;
      }else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    provinceChange(value) {
      this.form.address = value;
    },
    getPCH() {
      let province = [];
      this.City.forEach((item, index) => {
        let city = [];
        province.push({
          value: item.name,
          label: item.name,
          children: city,
        });
        item.cityList.forEach((list) => {
          city.push({
            value: list.name,
            label: list.name,
            loading: false,
          });
        });
        province[index].children = city;
      });
      this.options = province;
    },
    onSelectChange(selectedRowKeys,selectedRow) {
      this.billingConsolidatorKeys = selectedRowKeys;
      this.selectHBList = selectedRow;//选择需要合并的开票
    },
    handleOk(){
      //判断用户认证过程中不能开发票
      if(this.userInfoList.companyStatus !== 3){ // 0-未提交认证 1已提交待审核 2已审核未通过 3已通过审核
        this.$message.info("请认证审核通过后再开发票！");
      }else {
        this.getInvoiceHeader();
        this.addressList();
        let mon = 0;
        //计算金额
        for (let i = 0; i < this.consolidatorData.length; i++) {
          mon = mon + this.consolidatorData[i].orderMoney
        }
        this.invoiceValue = mon;
        this.billingConsolidator = false;
        this.billingConsolidatorKeys = [];
        this.applyBillingVisible = true;
        //选择发票内容
        this.invoiceContent = '技术服务费';
      }
    },
    invoiceInfoCancel(){
      this.invoiceInfoVisible = false;
      this.$refs.invoiceInfoFormRef.resetFields(); //清空表单
    },
    //修改发票信息
    async updateOrderInfo(data) {
      const response = await updatebyid(data);
      if (response.code === 0) {
        this.invoiceInfoVisible = false;
          this.$refs.invoiceInfoFormRef.resetFields(); //清空表单
          this.invoiceInfoForm = {};
        // if(this.memberStatus === 2){
        //   this.$refs.invoiceInfoFormRef.resetFields(); //清空表单
        //   this.invoiceInfoForm = {};
        // }else if(this.memberStatus === 1){
        //   this.$refs.invoiceInfoFormRefG.resetFields(); //清空表单
        //   this.invoiceInfoFormG = {};
        // }
        //刷新发票抬头信息
        await this.getInvoiceHeader();
      } else {
        this.$message.warning(response.message);
      }
    },
    invoiceInfoOk(){
      let data = {};
      //判断是企业还是个人
      // if(this.memberStatus === 2){ //企业
        this.$refs.invoiceInfoFormRef.validate((valid) => {
          if (valid) {
            let form = this.invoiceInfoForm;
            data = {
              "id": form.id,//必填，其他选填
              "name": form.name,//公司名称  发票抬头
              "taxNumber": form.taxNumber,//纳税人识别号
              "address": form.address,//公司注册地址
              "phone": form.phone,//公司注册电话
              "bank": form.bank,//基本开户银行
              "bankAccount": form.bankAccount,//基本开户账号
              "invoiceType":form.invoiceType //发票类型
            }
            // console.log(data)
            this.updateOrderInfo(data);
          }
        });
      // }else if(this.memberStatus === 1){ //个人
      //   //个人只能查看信息，无法修改
      //   this.invoiceInfoVisible = false;
      // }
    },
    addMailing(){
      this.mailingTitle = '新增邮寄地址';
      this.mailingVisible = true;
    },
    textareaChange(e){
      let value = e.target.value;

      let len = 0, j = 0; //len为字节数,j为字符数

      for (var i = 0; i < value.length; i++) {

        //charCodeAt(i)返回value第i个的UNcode值

        if (value.charCodeAt(i) > 127 || value.charCodeAt(i) === 94) {

          len += 2;

          j++;

        } else {

          len++;

          j++;

        }

        if (len > 230) {

          this.$message.warning("发票备注不可超过230个字节，一个中文字符为2个字节");

          this.InvoiceRemarks = value.substring(0, j - 1);

          break;

        }
      }
    },
    mailingCancel(){
      this.mailingVisible = false;
      this.$refs.form.resetFields();
    },
    mailingOk(){
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if(this.mailingTitle === '修改邮寄地址'){
            //修改
            let data = {
              id: this.form.id,
              name: this.form.name,
              phone: this.form.phone,
              addressProvince: this.form.address[0],
              addressCity: this.form.address[1],
              addressDetail: this.form.addressDetail,
            }
            const res = await editAddress(data);
            if (res.code == 0) {
              this.$message.success("修改成功");
              this.mailingVisible = false;
              this.$refs.form.resetFields(); //清空表单
              await this.addressList();
            } else {
              this.$message.warning(res.message);
            }
          }else if(this.mailingTitle === '新增邮寄地址'){
            //新增
            let dataAdd = {
              name: this.form.name,
              phone: this.form.phone,
              addressProvince: this.form.address[0],
              addressCity: this.form.address[1],
              addressDetail: this.form.addressDetail,
              isDefault: 0,
            }
            const res = await newAddress(dataAdd);
            if (res.code === 0) {
              this.$message.success("新增成功");
              this.mailingVisible = false;
              this.$refs.form.resetFields(); //清空表单
              await this.addressList();
            } else {
              this.$message.warning(res.message);
            }
          }
        }
      });
    },
    async postInvoices(data) {
      const res = await postInvoice(data);
      if (res.code === 200) {
        this.$message.success("提交成功");
        this.applyBillingVisible = false;
        await this.getInformation();
        //刷新列表
        this.orderList = [];
        await this.getOrderList();
        //清空选择的订单列表
        this.billingConsolidatorKeys = [];
        this.selectHBList = [];
      } else {
        this.$message.warning(res.message);
      }
    },
    submit(){
      //获取邮寄地址
      let addressInfo = {};
      for(let i=0;i<this.mailingAddressData.length;i++){
        if(this.selectedRowKeys[0] === this.mailingAddressData[i].id){ //判断选择的id,获取邮寄地址信息
          addressInfo = this.mailingAddressData[i];
        }
      }
      //获取开发票的订单列表
      let orderDetails = [];
      let list = this.selectHBList;
      for( let j=0;j<list.length;j++){
        let obj = {
          "id": list[j].id,
          "orderNumber": list[j].orderNumber, //"订单编号",
          "serviceName": list[j].serviceName, //"订购服务",
          "orderMoney": list[j].orderMoney, //"实际金额",
          "paymentTime": list[j].paymentTime, //"支付时间"
        };
        orderDetails.push(obj);
      }
      let form = this.invoiceData[0];

      let data = {};
      //判断，若是电子发票
      if(this.InvoiceMedium === '电子发票'){
        if(this.emileAddress){
          data =  {
            "type": this.memberStatus === 2 ? '企业':'个人', //"开票类型 个人/企业",
            "invoiceType": form.invoiceType, //"发票类型 增值税普通发票/增值税专用发票",
            "invoiceHeader": form.name,
            "invoiceMedia": this.InvoiceMedium, //"发票介质 纸质发票/电子发票",
            "invoiceMoney": this.invoiceValue, //发票金额
            "invoiceTaxRate": 6, //发票税率 整数
            "invoiceServiceName": this.invoiceContent, //"发票服务名称",
            "invoiceRemark": this.InvoiceRemarks, //"发票备注",

            "email":this.emileAddress, //电子邮箱地址

            "taxNumber": form.taxNumber, //"纳税人识别号",
            "bank": form.bank, //"基本开户银行",
            "bankAccount": form.bankAccount, //"基本银行账号",
            "companyAddress": form.address, //"公司注册地址",
            "companyPhone": form.phone, //"公司注册电话",

            // "addressProvince": addressInfo.addressProvince,
            // "addressCity": addressInfo.addressCity,
            // "addressDetail": addressInfo.addressDetail, //"详细邮寄地址",
            // "addressPhone": addressInfo.phone, //"收件联系电话",
            // "addressName": addressInfo.name, //"收件人",
            //合并开票
            "orderDetails": orderDetails //订单详情
          }
          this.postInvoices(data);
        }else {
          this.$message.warning("电子邮箱为空，请完善电子邮箱地址")
        }
      }
      //纸质发票
      else {
        if(this.selectedRowKeys.length && this.mailingAddressData.length){ //判断有没有地址，以及有没有被选中
          data =  {
            "type": this.memberStatus === 2 ? '企业':'个人', //"开票类型 个人/企业",
            "invoiceType": form.invoiceType, //"发票类型 增值税普通发票/增值税专用发票",
            "invoiceHeader": form.name,
            "invoiceMedia": this.InvoiceMedium, //"发票介质 纸质发票/电子发票",
            "invoiceMoney": this.invoiceValue, //发票金额
            "invoiceTaxRate": 6, //发票税率 整数
            "invoiceServiceName": this.invoiceContent, //"发票服务名称",
            "invoiceRemark": this.InvoiceRemarks, //"发票备注",

            // "email":this.emileAddress, //电子邮箱地址

            "taxNumber": form.taxNumber, //"纳税人识别号",
            "bank": form.bank, //"基本开户银行",
            "bankAccount": form.bankAccount, //"基本银行账号",
            "companyAddress": form.address, //"公司注册地址",
            "companyPhone": form.phone, //"公司注册电话",

            "addressProvince": addressInfo.addressProvince,
            "addressCity": addressInfo.addressCity,
            "addressDetail": addressInfo.addressDetail, //"详细邮寄地址",
            "addressPhone": addressInfo.phone, //"收件联系电话",
            "addressName": addressInfo.name, //"收件人",
            //合并开票
            "orderDetails": orderDetails //订单详情
          }
          this.postInvoices(data);
        }else {
          this.$message.info("请先选择邮寄地址~")
        }
      }
    },
    paginationChange(pageNo){
      // var top = document.body.scrollTop || document.documentElement.scrollTop
      // scrollBy(0,-top);
      this.orderList = [];
      this.page_no = pageNo;
      this.getOrderList();
    },
    close(){
      this.invoiceData = [];
      this.InvoiceMedium = null;
      this.invoiceContent = null;
      this.mailingAddressData = [];
      this.selectedRowKeys = [];
      this.InvoiceRemarks = '';
      this.invoiceValue = null;
      this.applyBillingVisible = false;
      this.getInformation();
      this.billingConsolidatorKeys = [];
      this.selectHBList = [];
    },
    onChange_InvoiceMedium(){
      // if(this.InvoiceMedium === '纸质发票'){ //默认选中默认地址
      //   if(this.mailingAddressData.length){
      //     let obj = this.mailingAddressData[0].id;
      //     let arr = [];
      //     arr.push(obj);
      //     this.selectedRowKeys = arr;
      //   }
      // }
    },
    onChange_invoiceContent(){

    }
  }
}
</script>

<style scoped lang="scss">
.OrderManagement{
  border: 10px solid #edeff2;
  .moTitle {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: auto;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    color: $topTitleColor;
  }
  .btn{
    width: 98%;
    margin: auto;
    padding:15px 0 15px;
  }
  .content-card{
    //height: 480px;
    //overflow-y: scroll;
    .card{
      width: 98%;
      padding: 20px;
      margin: auto;
      margin-bottom: 10px;
      min-height: 230px;
      border: 1px solid #edeff2;
      box-shadow: 1px 1px 15px #d7d7d7;
      .cardTop{
        .topTitle{
          font-weight: 600;
          color: $topTitleColor;
        }
        margin-bottom: 20px;
        margin-top: 10px;
        .cardTop-font{
          margin-left: 50px;
          margin-right: 50px;
        }
      }

    }
  }
  .applyBilling{
    .table{
      .rpa-table-header{
        overflow: hidden !important;;
        margin-bottom:auto!important;
      }
      ::-webkit-scrollbar {
        width:10px;
        height: 10px;
      }
      ::-webkit-scrollbar-thumb{
        background-color: #C1C1C1;
      }
      ::-webkit-scrollbar-track {
        background-color: #F1F1F1;
      }
    }
    .title{
      font-size: 16px;
      color: rgba(0,0,0,.65);
      font-weight: bold;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .content{
      margin-left: 50px;
    }
  }
}
.action-font{
  margin-right: 8px;
}
</style>
